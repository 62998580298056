@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overscroll-behavior-y: contain;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  position: fixed;
  overflow: hidden;
}

.animatedBlock {
  will-change: transform;
  position: absolute;
  user-select: none;
}

.animatedCard {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  will-change: transform;
  overflow: hidden;
}

.cardBlock {
  position: absolute;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card {
  background-color: rgb(210, 225, 225);
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  padding: 0 20px;

  width: 350px;
  height: 570px;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 20px -10px rgba(50, 50, 73, 0.4),
    0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

@media screen and (max-width: 768px) {
  .card {
    width: 250px;
    height: 350px;
  }

  .membername {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .role {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .favorite {
    font-size: 14px;
    margin-bottom: 14px;
  }

  .cause {
    font-size: 14px;
  }
}
